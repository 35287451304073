<template>
  <FormCard
    :title="print.name ? print.name : 'Nova gravação'"
    :status="print.status"
    @cancel="$router.push({ name: 'print' })"
    @save="validate"
  >
    <v-form
      ref="form"
      class="px-5 mt-5"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <SectionTitle title="Dados Básicos" />
          <v-text-field
            v-model="print.name"
            label="Nome"
            placeholder="Nome da gravação"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            dense
            rounded
            outlined
          />
          <v-row>
            <v-col
              v-for="(vendor, index) in vendorShow"
              :key="index"
              class="ml-8"
              cols="12"
              md="11"
            >
              <v-row
                class="py-2"
                align="center"
              >
                <div class="px-4">
                  <v-btn
                    icon
                    @click="removeRowVendor(index)"
                  >
                    <v-icon
                      color="error"
                      small
                    >
                      fas fa-times
                    </v-icon>
                  </v-btn>
                </div>
                <v-autocomplete
                  v-model="print.vendors[index]"
                  outlined
                  label="Fornecedor"
                  :items="vendors"
                  :filter="() => true"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  :item-text="(item) => item.company_name"
                  rounded
                  hide-details
                  dense
                  return-object
                  @update:search-input="(value) => onSearchInput(value, index)"
                  @focus="onFocusVendor"
                >
                  <template v-slot:append-item>
                    <div
                      v-intersect="onIntersect"
                      class="pa-2"
                    >
                      Carregando...
                    </div>
                  </template>
                </v-autocomplete>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="ml-2 mb-2"
            >
              <v-btn
                icon
                dark
                class="primary"
                @click="addVendor"
              >
                <v-icon small> fas fa-plus </v-icon>
              </v-btn>
              <span
                v-if="print.vendors.length === 0"
                outlined
                class="ml-2"
              >
                Adicione um fornecedor a essa gravação
              </span>
            </v-col>
            <v-col
              cols="12"
              md="1"
            />
          </v-row>
          <v-textarea
            v-model="print.description"
            label="Descrição"
            auto-grow
            outlined
            dense
            rounded
            rows="10"
            row-height="15"
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <SectionTitle title="Tabela de Preços" />
          <PrintCostTableRow
            v-for="(row, idx) in print.rows"
            :key="idx"
            :row="row"
            :length="print.rows.length"
            @removeRow="removeRow(row)"
          />
          <v-btn
            icon
            dark
            class="primary mt-2"
            @click="addRow"
          >
            <v-icon small> fas fa-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </FormCard>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    FormCard: () => import('@/components/FormCard'),
    PrintCostTableRow: () => import('./PrintCostTableRow'),
  },
  props: {
    valid: Boolean,
    print: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vendors: [],
      searchText: '',
      page: 1,
      isLastPage: false,
      vendorSelect: {},
      profile: null,
      isLoading: false,
    }
  },
  computed: {
    vendorShow() {
      return this.print.vendors || []
    },
  },
  created() {
    if (this.$cookies.get('type') === 'user' && this.type === 'edit') {
      if (this.$store.state.profile.length === 0) {
        this.getUserProfile()
      }
    }
    this.debouncedGetVendors = debounce(this.getVendors, 300)
    this.getVendors()
  },
  methods: {
    validate() {
      const isValid = this.$refs.form.validate()
      if (isValid) this.$emit('save')
    },
    onIntersect() {
      this.page += 1
      this.getVendors()
    },

    onSearchInput(value, index) {
      if (this.print.vendors[index]) return

      if (value === this.searchText) return

      this.searchText = value
      this.page = 1
      this.isLastPage = false
      this.debouncedGetVendors()
    },
    onFocusVendor() {
      if (!this.vendors.length) {
        this.getVendors()
      }
    },
    addRow() {
      this.print.rows.push({})
    },
    addVendor() {
      this.print.vendors.push(this.vendorSelect)
    },
    removeRow(row) {
      const idx = this.print.rows.indexOf(row)
      if (idx > -1) {
        this.print.rows.splice(idx, 1)
      }
    },
    removeRowVendor(index) {
      this.print.vendors.splice(index, 1)
    },
    getVendors() {
      if (this.isLoading) return
      this.isLoading = true

      this.$api
        .get(`vendors?page=${this.page}&search=${this.searchText || ''}`)
        .then((res) => {
          const data = res.data.data

          if (!data || data.length === 0) {
            this.isLastPage = true
          } else {
            if (this.page === 1) {
              this.vendors = data
            } else {
              this.vendors = [...this.vendors, ...data]
            }
          }

          if (Array.isArray(this.vendors)) {
            this.vendors.sort((a, b) =>
              a.company_name.localeCompare(b.company_name)
            )
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.isLoading = false
        })
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>
